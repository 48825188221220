.footer-section {
  background-color: var(--themeContrast) !important;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  margin: 0px;
  padding-top: 50px;
  }

.footer-section h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  padding: 10px;
  text-align: center;
  color: var(--themeMain);
}

.footerHead{
  margin: 30px 0;
  font-size: 24px;
  color: var(--themeMain);
  text-align: left;
}

.footerText{
  margin: 2px;
  font-size: 18px;
  color: var(--themeMain);
}

.footerIcon{
  margin: 0 5px;
  font-size: 28px;
  color: var(--themeMain);
}

.footerText:hover{
  transform: scale(1.02);
  cursor: pointer;
  transition: 100ms;
}

.footerIcon:hover{
  transform: translateX(-2px);
  cursor: pointer;
  transition: 100ms;
}

.footerBottom{
  padding-top: 50px;
}

.footerCopyRights{
  font-size: 14px;
  color: #fff;
}

.footerCopyRights:hover{
  color: var(--themeMain);
  cursor: pointer;
}

.footerCredit{
  text-align: right;
  font-size: 14px;
  color: #fff;
}

.footerCredit:hover{
  color: var(--themeMain);
  cursor: pointer;
}

@media screen and (max-width: 960px) {
.footerHead, .footerCredit, .footerCopyRights, .footerText, .footerIcon{
  text-align: center;
}
}