.serviceHead{
  height: 50vh;
  background-image: url(../images/servicesBg4.jpg);
  /* background: var(--themeMain); */
  background-size: cover;
 background-position: center;
}

.serviceBtnContainers{
  width: 100%;
}

.bgGradient {
  /* background-image: linear-gradient(to top, rgba(247, 247, 247, 0.9) 0, rgba(214, 214, 214, 0.9)100%); */
  background-image: linear-gradient(to top, rgba(139, 71, 39, 0.5) 0, rgba(37, 25, 25,0.5)100%);
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.serviceH1{
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: #fafaef;
  font-size: 60px;
  font-weight: 700;
  backdrop-filter: drop-shadow(10px 10px);
}

.serviceSection1{
  height: 20vh;
  width: 100%;
  background-color: #8b4627;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.serviceSection2{
  height: 20vh;
  width: 100%;
  background-color: var(--themeMain);
  color: #8b4627;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.servicesIcons{
  width: 60px;
  margin-bottom: 10px;
}
/* 
.serviceSection1:hover {
  transition: 800ms;
  background-color: #fafaef;
  transform: scale(1.1x);
  color: #000;
  font-size: 36px;
}

.serviceSection2:hover{
  transition: 800ms;
  background-color: #fafaef;
  transform: scale(1.1x);
  color: #000;
  font-size: 36px;
} */

.serviceSection2:hover .servicesText,.serviceSection1:hover .servicesText  {
  font-size: 36px;
  transition: 200ms;
}


.serviceCard {
  background-color: var(--themeMain) !important;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 250px;
  margin: 10px auto;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: transform 0.3s ease-in-out;
  padding: 10px 10px;
  position: relative;
  z-index: 1;
}


.serviceCard:hover {
  transform: scale(1.02);
}

.serviceBody{
  display: flex;
  flex-direction: column ;
  align-items: center;
}

.serviceCard__icon{
  margin: 10px 0;
  font-size: 35px;
}

.serviceCard__head {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  text-align: center;
}

.serviceCard__text {
  color: #000;
  margin: 8px 0 0;
  font-size: 14px;
  text-align: center;
}

.servicesText{
  text-align: center;
}

.serviceIconContainer{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.serviceHeadContainer{
  display: flex;
  justify-content: start;
  align-items: center;
}

.digital-box {
  background-color:  var(--themeMain);
  border: 0;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}

.digitalMarketing{
  background-color:  var(--themeMain);
  padding: 2em 1em;
}

.digitalTag{
  margin: 0 ;
  text-align: left;
  text-transform: uppercase;
  font-size: 12px;
}

.digitalInfoText{
  color: #000;
  text-align: justify;
}

.digitalTop{
  background-image: url(../images/digitalBg2.jpg);
  height: 35vh;
  background-position: center;
  background-size: cover;
}

.digitalHeader{
  text-align: left;
}

.bgGradientBlack{
  background-image: linear-gradient(to top, rgba(139, 71, 39, 0.5) 0, rgba(37, 25, 25,0.5));
  width: 100%;
  height: 100%;
  z-index: 1;
}

.contentCreation{
  background-color:  var(--themeMain);
  padding: 2em 1em;
}

.contentTop{
  background-image: url(../images/contentBg.jpg);
  height: 35vh;
  background-position: center;
  background-size: cover;
}

.content-box {
  background-color:  var(--themeMain);
  border: 0;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}

.contentTag{
  margin: 0 ;
  text-align: right;
  text-transform: uppercase;
  font-size: 12px;
} 

.contentInfoText{
  color: #000;
  text-align: justify;
}

.contentHeader{
  text-align: right;
}


@media screen and (max-width: 960px) {
.serviceCard{
  width: 215px;
  height: 215px;
}

.serviceCard__head{
  font-size: 14px;
}

.serviceCard__text{
  font-size: 12px;
}
}