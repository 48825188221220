.contactContainer {
    width: 100%;
    height: 100vh;
}

.top-color {
    width: 100%;
    height: 20vh;
    background-color: #fbfbf6;
    display: flex;
    align-items: flex-end;
}

.contactImage{
    position: absolute;
    top: 35%;
    left: 30%;
    width: 40%;
    margin-top: 60px;
}

.contactImage2{
    position: absolute;
    top: 35%;
    right: 5%;
    width: 30%;
    margin-top: 60px;
}

.bottom-color {
    width: 100%;
    height: 50vh;
    background-color: #8b4727;
    background: linear-gradient(to bottom right, #8b4627, #251919);
    display: flex;
    align-items: center;
    justify-content: center;
    
}
  
.middle-box {
    position: absolute;
    top: 20%;
    right: 10%;
    width: 30em;
    background-color: #251919bd;
    backdrop-filter: blur(5px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.contactHead-h1{
    font-family: "Poppins", sans-serif;
    font-size: 72px;
    font-weight: 700;
    display: block;
    margin: 0;
    z-index: 1;

    color: var(--themeMain);
}

.contactHead-p{
    font-family: "Poppins", sans-serif;
    color: var(--themeMain);
    font-size: 20px;
    margin-left: 5px;
    transform: translate(0px, -12px);
    font-style: italic;
}

.contactHead-h2{
    text-align: center;
    font-size: 36px;
    color: var(--themeMain);
}

.contactInfo{
    width: 100%;
    height: 100%;
    padding: 60px 0;
}

.contactItem{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.contactTextBottom{
    font-size: 20px;
    color: var(--themeMain);
}

.iconContainer{
    width: 50px;
    height: 50px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:  var(--themeMain);
}

.contactIcon{
    color: var(--themeContrast);
    font-size: 30px;
    padding: 5px 0;
}

.socialContainer{
    display: flex;
    justify-content: center;
}

.socialIcon{
    font-size: 40px;
    margin: 20px 20px 0 20px;
    color:  var(--themeMain);
}




.contactInfo2{
    width: 100%;
    height: 100%;
    padding: 0;
}


.iconCol{
    background-color: #fbfbf6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headCol{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}


.contactHead2-h2{
    background-color: #fbfbf6;
    width: 100%;
    text-align: center;
}

.contactRow{
    height: 160px;
}

.textCol{
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}


@media screen and (max-width: 768px) {
    .contactHead-h1{
        color:  var(--themeContrast);
        text-align: center;
    }    

    .contactHead-p{
        color:  var(--themeContrast);
        text-align: center;

    }

    .mobContainer{
        width: 100%;
    }

    .middle-box{
        position: absolute ;
        top:30%;
        width:100%;
        margin:auto auto;
        right: 0;
    }

    .top-color{
        height: 30vh;
        align-items: center;
    }
}