.home {
    padding-top: 5em;
    min-height: 80vh;
    background: linear-gradient(to bottom right, #8b4627, #251919);
}

.bgGradient {
    width: 100%;
    z-index: 1;
}

.homeLeftContainer {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

.homeH1Top {
    font-size: 64px;
    margin: 0;
    font-family: 'Ysabeau SC', sans-serif;
    font-weight: 700;
    color: var(--themeMain);
}

.homeH1Bottom {
    font-size: 80px;
    font-family: 'Ysabeau SC', sans-serif;
    font-weight: 700;
    background: linear-gradient(to right, var(--themeMain), #fff); 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 1;
    position: relative;
}


.homeText {
    color: #fafaef;
    font-size: 19px;
    text-align: justify;
    z-index: 1;
    position: relative;
}

.home-btn1{
    width: 100%;
    background-color: #753c21;
    color: #fafaef;
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 400;
    border: 2px solid #fafaef;
    padding: 25px;
    z-index: 1;
    position: relative;
    border-radius: 5px;
}

.home-btn2{
    width: 100%;
    background-color: #fafaef;
    color: #251919;
    margin-top: 10px;
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 400;
    border: 2px solid #422f2f;
    padding: 25px;
    z-index: 1;
    position: relative;
    border-radius: 5px;
}

.home-btn1:hover , .home-btn2:hover{
    transform: translate(5px, 5px);
    transition: 500ms;
}


.imgContainer {
    position: relative;
    width: 100%;
    height: 100%;
    /* backdrop-filter: blur(10px); */
    display: flex;
    justify-content: end;
    align-items: start;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .homeBgImage {
    position: relative;
    width: 90%;
    animation-name: floating;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}
  
  @keyframes floating {
    0% {
      transform: translate(0, 0);
      filter: drop-shadow(0px 30px 3px #200111);
    }
    50% {
      transform: translate(0, 20px);
      filter: drop-shadow(0px 50px 5px #200111);
    }
    100% {
      transform: translate(0, 0);
      filter: drop-shadow(0px 30px 3px #200111);
    }
  }
  
#tsparticles2 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0 auto;
    z-index: 0;
    width: 60%;
    height: 100%;
    animation: rotate 10s infinite linear;
}
     

.homeScreen {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100vh;
    z-index: 1;
    position: relative;
}

.hero-img {
    margin-top: 5em;
    width: 350px;
    height: auto;
    z-index: 1;
    position: relative;
}

.hero-text>h1 {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 64px;
    font-weight: 800;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    margin-top: 2rem;
}


.hero-text>h2 {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 48px;
    margin: 0;
    padding: 0;
}

.hero-text>h3 {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 40px;
    margin: 0;
    padding: 0;
    font-weight: 800;
}

.hero-text>p {
    text-align: center;
    margin-top: 8px;
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
}

.hero-btns {
    margin-top: 20px;
    text-align: right;
}

.hero-btns .btn {
    margin: 6px;
}


@media screen and (max-width: 960px) {
    .homeScreen {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .hero-text>h1 {
        font-size: 50px;
        text-align: center;
        margin-top: 1em;
    }

    .hero-text>p {
        text-align: center;
        margin-top: 8px;
        font-size: 24px;
    }

    .hero-btns {
        margin-top: 20px;
        text-align: center;
    }
}