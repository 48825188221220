.about-h1 {
  margin: 1em 0em 0.6em 0em;
  background: linear-gradient(to right, var(--themeContrast), var(--themeContrast2)); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 60px;
  font-weight: 700;
  display: block;
  text-align: center;
}

.about-box-light {
  background-color: rgba(251, 251, 246, 0.1);
  backdrop-filter: blur(10px);
  padding: 2em 1em;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 0;
  border-radius: 10px;
}

.about-box-dark {
  background-color: rgba(139, 70, 39, 0.1);
  backdrop-filter: blur(10px);
  padding: 1.5em 1em;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 0;
  border-radius: 10px;
  margin: 0.5em 1em;
  /* height: 320px; */
}

.fullDark{
  background-color: rgba(139, 70, 39, 1);
}

.about-h2 {
  margin: 10em 0em 0.6em 0em;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: 600;
  display: block;
  text-align: center;
}

.about-h3 {
  margin: 0.2em 0em;
  font-family: "Poppins", sans-serif;
  font-size: 26px;
  font-weight: 600;
  display: block;
}

.aboutText {
  margin-bottom: 1em;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 400;
  text-align: justify;
}

.bigText{
  font-size: 42px;
}

  @media screen and (max-width: 960px) {
  .about h1 {
    font-size: 42px;
  }

  .pagecentre {
    float: none;
    width: 100%;
    margin: 0 auto;
    max-width: 80%;
  }

  .about-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .about-box {
    width: 100%;
    max-width: 960px;
    padding: 2em;
    margin-bottom: 2em;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .pagecentre {
    float: none;
    width: 100%;
    margin: 0 auto;
    max-width: 90%;
  }

  .about-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .about-box {
    width: 100%;
    max-width: 960px;
    padding: 2em;
    margin-bottom: 2em;
  }
}
