.navbarBg {
    padding-bottom: 10px;
    background-color: var(--themeMain) !important;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    margin-bottom: 0px;
    min-height: 50px;
    text-transform: capitalize;
    min-height: 10vh;
}

.brandImage{
    width: 250px;
  }

  .navbar-light .navbar-nav .nav-link {
    color: var(--themeContrast);      
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    text-align: center;
    margin: 15px 35px;
    padding: 15px 8px !important;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 2px var(--themeMain) solid;
  }
  

.navbar-light:hover .navbar-nav:hover .nav-link:hover{
    transition: 300ms;
    transform: scale(1.05);
    border-bottom: 2px var(--themeContrast) solid;
  } 

.navbar-expand-lg .navbar-nav .dropdown-menu {
    background: var(--themeMain);
    color: var(--themeContrast);
    border-radius: 10px;
  border: 1px solid var(--themeContrast);
  border: 0;
    min-width: 200px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.nav-link.active:hover {
    border-bottom: 2px var(--themeContrast) solid !important;
  }


  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
    cursor: var(--pointer);
  }
  
  .dropdown-item:hover{
    color: var(--themeMain) !important;
    background: var(--themeContrast) !important;
    cursor: pointer;
    transition: 600ms;
  }
  
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 0 !important;
    clear: both;
    text-decoration: none;
    color: var(--themeContrast);
    font-weight: 600 !important;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    cursor: var(--pointer);
    text-align: center !important;
  }
  
  .dropdown-menu.show {
    position: static;
  }

@media screen and (max-width: 960px) {
    .navbarBg {
        padding-bottom: 10px;
        margin-bottom: 0px;
        min-height: 10vh;   
}

    .brandImage {
        width: 200px;
    }

    .navbar-dark .navbar-nav .nav-link {
        text-align: center;
        padding: 30px 10px; 
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        align-items: center;
        width: 100%;
    }

}